.toggle-size-variant(@toogle-height,@min-width,@toggle-handle-gap,@toggle-inner-margin,@toggle-inner-font-size) {
  @handle-diameter: @toogle-height - @toggle-handle-gap*2;

  height: @toogle-height;
  min-width: @min-width;
  border-radius: @toogle-height / 2;

  &::after {
    width: @handle-diameter;
    height: @handle-diameter;
    left: @toggle-handle-gap;
    top: @toggle-handle-gap;
    border-radius: @handle-diameter / 2;
  }

  &:active::after {
    width: @handle-diameter * @toggle-active-scale;
  }

  .@{ns}btn-toggle-inner {
    margin-left: @toogle-height;
    margin-right: @toggle-inner-margin;
    height: @toogle-height;
    line-height: unit(@toogle-height / @toggle-inner-font-size);
    font-size: @toggle-inner-font-size;
  }

  &.@{ns}btn-toggle-checked {
    &::after {
      left: 100%;
      margin-left: -(@handle-diameter + @toggle-handle-gap);
    }

    &:active::after {
      margin-left: -(@handle-diameter * @toggle-active-scale + @toggle-handle-gap);
    }

    .@{ns}btn-toggle-inner {
      margin-right: @toogle-height;
      margin-left: @toggle-inner-margin;
    }
  }
}

// Small
.btn-toggle(sm) {
  .toggle-size-variant(
    @toggle-sm-height,
    @toggle-sm-min-width,
    @toggle-sm-handle-gap,
    @toggle-sm-inner-margin,
    @toggle-sm-inner-font-size
  );
}

// Middle
.btn-toggle(md) {
  .toggle-size-variant(
    @toggle-md-height,
    @toggle-md-min-width,
    @toggle-md-handle-gap,
    @toggle-md-inner-margin,
    @toggle-md-inner-font-size
  );
}

// Large
.btn-toggle(lg) {
  .toggle-size-variant(
    @toggle-lg-height,
    @toggle-lg-min-width,
    @toggle-lg-handle-gap,
    @toggle-lg-inner-margin,
    @toggle-lg-inner-font-size
  );
}
