@import 'common';

//
// Scaffolding
// --------------------------------------------------

// Reset the box-sizing
//
// Heads up! This reset may cause conflicts with some third-party widgets.
// For recommendations on resolving such conflicts, see
// http://getbootstrap.com/getting-started/#third-box-sizing
* {
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: border-box;
}

// Body reset

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: @font-family-base;
  font-size: @font-size-base;
  line-height: @line-height-base;
  color: @text-color;
  background-color: @body-bg;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

// Links

a {
  color: @link-color;
  text-decoration: none;

  &:hover,
  &:focus {
    color: @link-hover-color;
    text-decoration: @link-hover-decoration;
  }

  &:focus {
    .tab-focus();
  }
}

// Images

img {
  vertical-align: middle;
}

// Horizontal rules
hr {
  margin-top: @line-height-computed;
  margin-bottom: @line-height-computed;
  border: 0;
  border-top: 1px solid @hr-border;
}
