@import 'common';
@import 'mixins/picker';
@import 'mixins/dropdown';

// MultiCascader
// ----------------------

// Cascader Picker Name Space
@cspns: ~'@{ns}@{cascader-picker-prefix}';
@cmpns: ~'@{ns}@{cascader-multi-picker-prefix}';

.@{cspns} {
  &-menu .@{ns}picker-check-menu-item {
    padding-right: 26px; // 12px + 8px + 6px
    word-break: break-word;
  }

  &-menu-column&-menu-column-uncheckable {
    .@{ns}picker-check-menu-item {
      padding-left: @picker-item-content-padding-horizontal;
    }
  }
}

.@{cmpns}-menu .@{cspns}-col {
  padding: 0;
}

