.input-touchspin(@size) {
  @size-name: ~'input-height-@{size}';
  @height: @@size-name / 2;

  height: @height;

  > .@{ns}icon {
    height: @height;
    line-height: unit(@height / @number-input-touchspin-font-size);
  }
}
