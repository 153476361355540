@import 'common';
@import 'mixins/tag';

//
// Time lines
// --------------------------------------------------

.@{ns}tag {
  display: inline-block;
  padding: @padding-extra-small-vertical @padding-extra-small-horizontal;
  background-color: @tag-default-background;
  color: @tag-default-color;
  border-radius: @border-radius-base;

  &-closeable {
    padding-right: 25px;
    position: relative;
  }

  &-icon-close {
    position: absolute;
    top: 4px;
    right: @padding-extra-small-horizontal;
    font-style: normal;
    font-family: @font-family-icon;
    cursor: pointer;
    font-size: 12px;

    &::after {
      .icon-font;

      content: @default-close-btn-content;
    }
  }

  &-default &-icon-close:hover {
    color: @tag-toggle-clean-hover-color;
  }

  &-group {
    margin: -1 * @tag-gap 0 0 -1 * @tag-gap;
  }

  &-group > & {
    margin-top: @tag-gap;
    margin-left: @tag-gap;
  }
}

.@{ns}tag + .@{ns}tag {
  margin-left: @tag-gap;
}

.generate-spectrum-tags;
