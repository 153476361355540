@import 'common';

//
// Time lines
// --------------------------------------------------

.@{ns}timeline {
  list-style: none;
  padding: 0;
  margin-bottom: 0;

  &-item {
    position: relative;
    padding-left: @time-line-item-padding-left;
  }

  &-item:not(:last-child) &-item-content {
    padding-bottom: @time-line-item-content-padding-bottom;
  }

  &-item-dot {
    position: absolute;
    top: @time-line-dot-center-gap;
    left: 0;

    &::before {
      content: '';
      display: block;
      width: @time-line-dot-side-length;
      height: @time-line-dot-side-length;
      background-color: @time-line-dot-default-background-color;
      border-radius: 50%;
    }
  }

  &-item-custom-dot {
    text-align: center;

    &::before {
      display: none;
    }
  }

  &-item-last &-item-dot::before {
    background-color: @time-line-last-dot-default-background-color;
  }

  &-item-tail {
    position: absolute;
    top: 0;
    bottom: 0;
    left: (@time-line-dot-side-length - @time-line-tail-width)/2;
    width: @time-line-tail-width;
    background-color: @time-line-tail-default-background-color;
  }

  // The first tail need reserved a space.
  &-item:first-child &-item-tail {
    top: @time-line-dot-center-gap;
  }

  // Last item tail need set height.
  &-item:last-child &-item-tail {
    bottom: auto;
    height: @time-line-dot-center-gap+ @time-line-dot-side-length;
  }
}
