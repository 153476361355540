@import 'common';

//
// Slider
// --------------------------------------------------
.@{ns}slider {
  position: relative;

  .@{ns}tooltip {
    display: none;

    &.@{ns}tooltip-placement-top .@{ns}tooltip-arrow {
      margin: auto;
      left: 0;
      right: 0;
    }
  }

  &-disabled {
    opacity: 0.3;
    cursor: not-allowed;

    .@{ns}slider-bar,
    .@{ns}slider-handle::before {
      cursor: not-allowed;
    }
  }

  &-with-mark:not(&-vertical) {
    //White space for mark
    margin-bottom: @line-height-computed + @slider-mark-margin-top;
  }
}

// Mark
.@{ns}slider-mark {
  position: absolute;
  top: @slider-bar-side-length + @slider-mark-margin-top;
  left: -2px;
  white-space: nowrap;

  &-content {
    margin-left: -50%;
  }
}

.@{ns}slider-last-mark {
  left: auto;
  right: -2px;

  .@{ns}slider-mark-content {
    margin-left: auto;
    margin-right: -50%;
  }
}

// Bar
.@{ns}slider-bar {
  height: @slider-bar-side-length;
  border-radius: @slider-bar-side-length / 2;
  background-color: @slider-bar-default-bg;
  cursor: pointer;

  &:hover {
    background-color: @slider-bar-hover-bg;
  }

  .@{ns}slider-vertical & {
    height: 100%;
    width: @slider-bar-side-length;
  }
}

// Graduator
.@{ns}slider-graduator {
  display: table;
  width: 100%;

  ul,
  li {
    list-style: none;
  }

  > ul {
    display: table-row;
    width: 100%;

    > li {
      display: table-cell;
      position: relative;

      &:last-child::after,
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: @slider-calibration-diameter;
        height: @slider-calibration-diameter;
        border-radius: 50%;
        background-color: @slider-calibration-bg;
        box-sizing: border-box;
        border: @slider-calibration-border-width solid @slider-calibration-border-color;
        margin-left: -4px;
        top: -1px;
        transition: border-color 0.3s ease-in;

        // Vertical styles
        .@{ns}slider-vertical & {
          top: -4px;
          margin-left: -1px;
        }
      }

      &:last-child::after {
        right: -4px;

        // Vertical styles
        .@{ns}slider-vertical & {
          left: 0;
          top: auto;
          bottom: -4px;
        }
      }

      &.@{ns}slider-pass::before {
        border-color: @slider-calibration-pass-border-color;
      }

      &.@{ns}slider-active::before {
        visibility: hidden;
      }
    }
  }

  // Vertical styles
  .@{ns}slider-vertical & {
    display: block;

    > ul {
      width: @slider-bar-side-length;

      &,
      & > li {
        display: block;
        padding: 0;
      }
    }
  }
}

// Progress bar
.@{ns}slider-progress-bar {
  position: absolute;
  height: @slider-bar-side-length;
  border-radius: @slider-bar-side-length / 2 0 0 @slider-bar-side-length / 2;
  background-color: @slider-progress-bar-bg;

  .@{ns}slider-vertical & {
    width: @slider-bar-side-length;
    border-radius: @slider-bar-side-length / 2 @slider-bar-side-length / 2 0 0;
  }
}

// Handle
.@{ns}slider-handle {
  position: absolute;
  top: -50%;

  &::before {
    content: '';
    position: absolute;
    width: @slider-handle-diameter;
    height: @slider-handle-diameter;
    border-radius: 50%;
    border: @slider-handle-border-width solid @slider-handle-default-border-color;
    background-color: @slider-handle-default-bg;
    margin-left: -@slider-handle-diameter / 2;
    cursor: pointer;
    transition: box-shadow @slider-handle-transition,
      background-color @slider-handle-transition,
      transform @slider-handle-transition;
  }

  &:hover::before {
    box-shadow: @slider-handle-hover-box-shadow;
  }

  .@{ns}slider-dragging &::before,
  .@{ns}slider-dragging &:hover::before {
    box-shadow: none;
    transform: scale(1.2);
  }

  .@{ns}slider-vertical &::before {
    left: (@slider-handle-diameter - @slider-bar-side-length)/2;
    margin-top: -@slider-handle-diameter / 2;
  }
}

.@{ns}slider-handle:hover .@{ns}tooltip,
.@{ns}slider-showtip .@{ns}tooltip {
  display: block;
  opacity: 1;
  top: -30px;

  .@{ns}slider-vertical & {
    top: -33px;
    // Make sure tooltip align center.
    margin-left: 3px;
  }
}

// Vertical
.@{ns}slider-vertical {
  height: 100%;

  // Reset all setting
  .@{ns}slider-mark {
    top: -8px;
    left: @slider-bar-side-length + @slider-mark-margin-top;

    &-content {
      margin-left: auto;
    }
  }

  .@{ns}slider-last-mark {
    top: auto;
    bottom: -8px;
  }
}
