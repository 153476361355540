@import 'common';
@import 'mixins/form';
@import 'mixins/picker';

.@{ns}@{input-picker-prefix} {
  position: relative;
  border: @picker-default-toggle-border-width solid @picker-default-toggle-border-color;
  border-radius: @border-radius-base;

  .@{ns}picker-toggle {
    border: none !important;
    height: @input-picker-toggle-content-height;

    &-clean,
    &-caret {
      top: @padding-base-vertical - @picker-default-toggle-border-width;
    }
  }

  &:not(.@{ns}picker-disabled) .@{ns}picker-toggle {
    position: absolute !important;
    top: -1px;
    left: -1px;
  }

  &:not(.@{ns}picker-disabled):hover,
  &.@{ns}picker-focused {
    border-color: @picker-default-toggle-hover-border-color;
  }
}

.@{ns}picker-tag-wrapper {
  padding-right: @dropdown-toggle-padding-right;

  .@{ns}picker-cleanable & {
    padding-right: @dropdown-toggle-padding-right + @picker-toggle-clean-width;
  }
}

.@{ns}picker-search {
  border: none;
  width: 100%;

  &-input {
    .input-md;

    .@{tag-picker} & {
      .input-size(
        @padding-extra-small-vertical; @padding-base-input-horizontal; @font-size-base; @line-height-computed; @input-border-radius
      );

      padding-left: @padding-base-horizontal - @picker-default-toggle-border-width;

      > input {
        background: none;
        outline: none;
        border: none;
        width: 100%;
      }
    }

    background: none;
    outline: none;
    border: none;
    width: 100%;
    position: relative;
    padding-right: 0;

    .@{ns}picker-focused & {
      z-index: @zindex-picker-input;
    }
  }
}
