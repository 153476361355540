@import 'common';
@import 'mixins/checkbox';

.@{ns}checkbox-checker {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: @checkbox-sense-width*2 + @checkbox-width-height;
  min-height: @checkbox-sense-width*2 + @checkbox-width-height;
  line-height: 1;
  position: relative;
}

.@{ns}checkbox-wrapper {
  position: absolute;
  width: @checkbox-width-height;
  height: @checkbox-width-height;
  display: inline-block;
  left: @checkbox-sense-width;
  top: @checkbox-sense-width;

  [type='checkbox'] {
    display: none;
  }

  &::before,
  &::after,
  .@{ns}checkbox-inner::before,
  .@{ns}checkbox-inner::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
  }

  &::before,
  .@{ns}checkbox-inner::before,
  .@{ns}checkbox-inner::after {
    width: @checkbox-width-height;
    height: @checkbox-width-height;
  }

  // Ripple effect area
  &::before {
    .checkbox-inner-wrap(@checkbox-checked-border-color);

    transform: scale(1);
    opacity: 0.7;
    visibility: hidden;
    transition: transform 0.2s linear, opacity 0.2s linear;

    .@{ns}checkbox-checked & {
      transform: scale(1.5);
      opacity: 0;
      visibility: visible;
    }
  }

  // Sense area(Can be clicked)
  &::after {
    top: -@checkbox-sense-width;
    right: -@checkbox-sense-width;
    bottom: -@checkbox-sense-width;
    left: -@checkbox-sense-width;
  }

  // Out border
  .@{ns}checkbox-inner::before {
    .checkbox-inner-wrap(@checkbox-default-border-color);

    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;

    .@{ns}checkbox-checked &,
    .@{ns}checkbox-indeterminate & {
      border-color: @checkbox-checked-border-color;
      background-color: @checkbox-checked-border-color;
    }

    .@{ns}checkbox-disabled:not(.@{ns}checkbox-checked):not(.@{ns}checkbox-indeterminate) & {
      border-color: @checkbox-disabled-color;
      background-color: @checkbox-disabled-color;
    }

    .@{ns}checkbox-disabled.@{ns}checkbox-checked &,
    .@{ns}checkbox-disabled.@{ns}checkbox-indeterminate & {
      opacity: 0.3;
    }
  }

  // Check mark
  .@{ns}checkbox-inner::after {
    opacity: 0;
    transform: rotate(45deg) scale(0);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);

    .@{ns}checkbox-checked &,
    .@{ns}checkbox-indeterminate & {
      border: solid @B000;
      width: 6px;
      height: 9px;
      margin-top: 2px;
      margin-left: 5px;
      opacity: 1;
    }

    .@{ns}checkbox-checked & {
      border-width: 0 2px 2px 0;
      transform: rotate(45deg) scale(1);
    }

    .@{ns}checkbox-indeterminate & {
      border-width: 0 0 2px;
      transform: rotate(0deg) scale(1);
      width: 10px;
      margin-top: 0;
      margin-left: 3px;
    }
  }

  // Disabled checkbox can't focus
  .@{ns}checkbox:not(.@{ns}checkbox-disabled) &:focus {
    outline: none;

    .@{ns}checkbox-inner::before {
      .checkbox-inner-active-shadow(@checkbox-default-border-color);

      @hover: ~':hover';

      @{hover}& {
        .checkbox-inner-active-shadow(@checkbox-checked-border-color);
      }

      .@{ns}checkbox-checked & {
        .checkbox-inner-active-shadow(@checkbox-checked-border-color);
      }
    }
  }
}

.@{ns}checkbox-group-inline {
  margin-left: -1 * @radio-sense-width;
}
