@import 'common';
@import 'mixins/message';

//
// Messages
// --------------------------------------------------

// Base styles
// -------------------------

.@{ns}message {
  border-radius: @message-border-radius;
  font-size: @font-size-base;
  line-height: @line-height-base;
  position: relative;

  .@{ns}message-container {
    padding: 20px;
  }

  // Multiple messages should have spacing
  // Hiding Message haven't spacing.
  &:not(.@{ns}message-hiding) + .@{ns}message {
    margin-top: 10px;

    //The last message hiding haven't clearance.
    &.@{ns}message-hiding:last-child {
      transition: margin-top 0.1s linear 0.3s;
      margin-top: 0;
    }
  }

  // Only has icon
  &.@{ns}message-has-icon .@{ns}message-container {
    padding-left: @message-padding + @message-icon-size + @message-icon-margin-right;
  }

  // Only has title
  &.@{ns}message-has-title {
    h5 {
      margin-top: 0;
      margin-bottom: 0;
      .ellipsis;

      // When title and description all exist , additional a margin
      & + .@{ns}message-description {
        margin-top: 8px;
      }
    }
  }

  // Has icon && title
  &.@{ns}message-has-icon.@{ns}message-has-title .@{ns}message-container {
    padding-left: @message-padding + @message-icon-large-size + @message-icon-margin-right;

    // Bigger icon
    .@{ns}message-icon-wrapper .@{ns}icon {
      font-size: @message-icon-large-size;
      line-height: 1;
    }
  }

  // Title
  h5 {
    color: @B900;
    line-height: unit(round(@message-icon-large-size / @message-title-size, 16));
  }

  // Description
  .@{ns}message-description {
    color: @B800;
  }

  // Icon wrapper
  .@{ns}message-icon-wrapper {
    position: absolute;
    top: @message-padding;
    left: @message-padding;
    line-height: 1;
  }

  // Icon
  .@{ns}message-icon-wrapper .@{ns}icon {
    font-size: @message-icon-size;
    line-height: @line-height-small;
  }

  // Close button
  .@{ns}message-btn-close {
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
    padding: @message-close-btn-padding;
    font-size: @message-close-btn-size;
    line-height: 1;
    border: none;
    outline: none !important;

    .icon-font;

    &::before {
      content: @default-close-btn-content;
    }

    // hidden aria-hidden dom
    [aria-hidden] {
      display: none;
    }
  }

  // Animations
  // ----------------------------
  &.@{ns}message-show {
    animation: messageMoveIn 0.3s ease-in forwards;
  }

  &.@{ns}message-hiding {
    animation: messageMoveOut 0.3s ease-in forwards;
  }
}

// Full the containers
.@{ns}message-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
}

// Alternate styles
// -------------------------
// Generate contextual modifier classes for colorizing the message.

.@{ns}message-success {
  .message-variant(@message-success-bg; @message-success-icon-color);
}

.@{ns}message-info {
  .message-variant(@message-info-bg; @message-info-icon-color);
}

.@{ns}message-warning {
  .message-variant(@message-warning-bg; @message-warning-icon-color);
}

.@{ns}message-error {
  .message-variant(@message-error-bg; @message-error-icon-color);
}
