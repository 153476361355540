@import 'common';

.help-block {
  &-success {
    color: @success-color;
  }

  &-warning {
    color: @warning-color;
  }

  &-danger {
    color: @error-color;
  }

  &-info {
    color: @info-color;
  }
}
