// Mixins
// --------------------------

.icon-rotate(@degrees) {
  transform: rotate(@degrees);
}

.icon-size(@size) {
  @font-size: @size * @icon-font-size-base;

  &,
  &.@{icon-css-prefix} {
    height: @font-size;
    font-size: @font-size;

    > svg {
      height: @font-size;
      // Fixed bug that ie svg width too long.
      .ie-width(@size * @icon-font-size-base);
    }
  }
}

.generate-icon-sizes(@i:length(@icon-font-sizes)) when (@i>0) {
  .generate-icon-sizes(@i - 1);

  .@{icon-css-prefix}-size-@{i}x {
    .icon-size(@i);
  }
}

.generate-stack-sizes(@i:length(@icon-font-sizes)) when (@i>0) {
  .generate-stack-sizes(@i - 1);

  .@{icon-css-prefix}-stack-size-@{i}x {
    font-size: @i*1em;
  }
}
