@import 'common';
@import 'mixins/tree-picker';

// Tree Picker
// ----------------------

// tree picker name space
@tpns: ~'@{ns}@{tree-picker-prefix}';

.@{tpns}-view {
  background-color: @picker-tree-bg;
  max-height: 360px;
  height: 100%;
  overflow-y: auto;

  /* stylelint-disable-next-line */ // This class name provide by Virtualized.
  .ReactVirtualized__Grid.ReactVirtualized__List:focus {
    outline: none;
  }
}

.@{tpns}-view-node {
  position: relative;
  font-size: @picker-tree-node-font-size;
  line-height: @picker-tree-node-line-height;

  &-active > .@{tpns}-view-node-label {
    color: @picker-tree-node-active-color;
    background-color: @picker-tree-node-active-bg;
  }

  > .@{tpns}-view-node-label {
    margin: 0;
    padding: @picker-tree-node-padding-vertical @picker-tree-node-padding-horizontal;
    //text gap
    padding-left: @picker-tree-arrow-down-width+ @picker-tree-arrow-down-gap+8px;
    display: inline-block;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      width: @picker-tree-arrow-down-width+ @picker-tree-arrow-down-gap;
      height: 100%;
      background-color: @picker-tree-bg;
      top: 0;
      margin-left: -24px;
    }

    &:focus,
    &:hover {
      background-color: @picker-tree-node-hover-bg;
    }
  }

  &-disabled {
    color: @picker-menu-item-disabled-color;
    cursor: @cursor-disabled;

    > .@{tpns}-view-node-label {
      cursor: @cursor-disabled;

      &,
      &:hover {
        background: transparent;
      }
    }
  }

  &:not(&-disabled):focus > .@{tpns}-view-node-label {
    background-color: @picker-tree-node-hover-bg;
  }
}

// expand icon
.@{tpns}-view-node-expand-icon-wrapper {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  z-index: 1;

  > .@{tpns}-view-node-expand-icon {
    display: inline-block;
    padding: @picker-tree-node-padding-vertical 0;
    padding-right: @picker-tree-arrow-down-gap;
    height: 36px;
    font-size: @picker-tree-node-font-size;
    line-height: @picker-tree-node-line-height;
    transform: rotate(-90deg) translateX(-2px);
    transform-origin: 3.5px 16px;
    margin-left: -2px;
    font-family: @font-family-icon;
    font-style: normal;
    user-select: none;

    &::before {
      content: @picker-tree-arrow-down;
    }

    // expand children tree
    .@{tpns}-view-open > .@{tpns}-view-node & {
      transform: rotate(0deg);
      margin-left: 0;
      margin-top: 0;
    }
  }

  > .@{tpns}-view-node-expanded {
    transform: rotate(0deg);
    margin-left: 0;
    margin-top: 0;
  }

  > .@{tpns}-view-custom-icon {
    .node-item-prepend-icon;
  }
}

// node children
.@{tpns}-view-node-children {
  > .@{tpns}-view-children {
    display: none;

    .@{tpns}-view-open& {
      display: block;
    }
  }
}

.@{ns}picker-menu {
  &.@{tpns}-menu {
    padding-top: @picker-menu-padding;

    .@{ns}picker-search-bar {
      padding-top: 0;
    }
  }

  .@{tpns}-view {
    padding: 0 @picker-menu-padding @picker-menu-padding 0;

    &-node > .@{tpns}-view-node-label {
      .ellipsis;

      display: block;
    }
  }
}
