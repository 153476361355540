@import 'common';

// Date range Picker
// ----------------------

// Date range Picker Name Space
@drpns: ~'@{ns}@{date-range-picker-prefix}';

.@{drpns} {
  .@{ns}picker-toggle-caret::before {
    content: @calendar-default-caret-content !important;
    // Adjust the line-height to make sure icon vertical align middle.
    line-height: 17px;
  }
}

.@{drpns}-menu {
  .@{ns}@{calendar-picker-prefix} {
    display: inline-block;
    width: 255px;
    height: 278px;
    padding-bottom: 12px;

    &:first-child {
      border-right: @date-range-picker-inner-border;
    }

    &-header {
      width: 100%;
      text-align: center;
    }

    &-header-month-toolbar {
      float: none;
    }
  }

  .@{ns}@{calendar-picker-prefix}-month-dropdown {
    z-index: @zindex-date-range-picker-calendar-dropdown;

    &-list {
      width: 185px;
    }
  }

  .@{ns}picker-toolbar {
    margin-top: 4px;
  }
}

// Header
.@{drpns}-header {
  padding: @date-range-picker-header-padding-vertical @picker-menu-padding;
  font-size: @date-range-picker-header-font-size;
  line-height: @date-range-picker-header-line-height;
  border-bottom: @date-range-picker-inner-border;
}

// Calendar group
.@{drpns}-calendar-group {
  height: 274px;
}
